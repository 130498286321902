import http from './httpService';

const apiEndpoint = `/api/algorithm`;
function getAlgorithmUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getAlgorithm(id) {
  return http.get(getAlgorithmUrl(id));
}

export function getAlgorithmDataModel() {
  return http.get(`${apiEndpoint}/dataModel`);
}

export function getAlgorithms() {
  return http.get(apiEndpoint);
}

export function deleteAlgorithm(id) {
  return http.delete(getAlgorithmUrl(id));
}

export function saveAlgorithm(algorithm, id) {
  if (id === -1) {
    // create new
    return http.post(apiEndpoint, algorithm);
  }
  return http.put(getAlgorithmUrl(id), algorithm);
}

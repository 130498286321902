import React, { useEffect, useState } from 'react';
import { paginate } from '../utils/paginate.js';
import { truncateString } from '../utils/helpers.js';
import Input from './common/input.jsx';
import Pagination from './common/pagination.jsx';

import { Link, useHistory } from 'react-router-dom';
import { getAlgorithms } from '../services/algorithmService.js';
import { toast } from 'react-toastify';

import CoverImage from '../cover-image.png';
import FAB from './common/fab.jsx';
import Icon from './common/icon.jsx';
import NotificationContainer from './NotificationContainer';

const SearchBar = ({ name, label, searchBarCallBack }) => {
  const [data, setData] = useState({ inputSearch: '' });
  const [errors] = useState({ inputSearch: '' });

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
    searchBarCallBack(input.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label={label}
        type='text'
        labelSize='h4'
        value={data[name]}
        name={name}
        onChange={handleChange}
        placeholder={`Zoek een model.. `}
        error={errors[name]}
      />
    </form>
  );
};

const FilterBlock = ({ filter, filterItem, filterGroup, updateFilter, algorithms }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [outCollapsed, setOutCollapsed] = useState(true);
  const [nItems, setNItems] = useState(0);

  const showItems = 5; //items to display if there are more than this they are hidden

  const getCountPerFilterValue = (filterGroup, value, algorithms) => {
    const filteredAlgorithms = algorithms.filter((algorithm) => {
      if (algorithm[filterGroup]) {
        if (Array.isArray(algorithm[filterGroup])) {
          return algorithm[filterGroup].includes(value);
        } else {
          return algorithm[filterGroup] === value;
        }
      }
      return false;
    });

    return filteredAlgorithms.length;
  };

  const toggleCollapsed = () => {
    setCollapsed((c) => !c);
  };

  const toggleOuterCollapse = () => {
    const c = outCollapsed;
    // console.log('before collapsed: ', c);
    setOutCollapsed(!c);
  };

  useEffect(() => {
    function initSelections() {
      setNItems(filterItem.listData.length);
    }
    initSelections();
  }, []);

  const handleExclusivity = (event, group, value) => {
    const checked = event.target.checked;
    updateFilter({ group, value, checked });
  };

  return (
    <div className='form-group'>
      <span
        data-toggle='collapse'
        href={`#outerCollapse-${filterGroup}`}
        role='button'
        onClick={toggleOuterCollapse}
      >
        <Icon
          name={outCollapsed ? 'chevron-forward' : 'chevron-down'}
          style={{ width: '1rem', height: '1rem' }}
        />
        <span className='h6' key={filterGroup}>
          {filterItem.displayName}
        </span>
      </span>

      <div className={`collapse`} id={`outerCollapse-${filterGroup}`}>
        {filterItem.listData.slice(0, showItems).map((value) => {
          const resultCount = getCountPerFilterValue(filterGroup, value, algorithms);
          return (
            <div className='form-check' key={`${filterGroup}-${value}`}>
              <input
                className='form-check-input'
                type='checkbox'
                id={`${filterGroup}-${value}`}
                checked={filter[filterGroup][value] ? filter[filterGroup][value] : false}
                onChange={(event) => handleExclusivity(event, filterGroup, value)}
              />
              <label className='form-check-label' htmlFor={`${filterGroup}-${value}`}>
                {value}
              </label>
              <label className='filter-count-label'> {`\xa0(${resultCount})`} </label>
            </div>
          );
        })}
        {nItems - showItems > 0 && (
          <div>
            <div className={`collapse`} id={`collapse-${filterGroup}`}>
              {filterItem.listData.slice(showItems).map((value) => {
                const resultCount = getCountPerFilterValue(filterGroup, value, algorithms);
                return (
                  <div className='form-check' key={`${filterGroup}-${value}`}>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      // filterGroup={filterGroup}
                      // filterValue={value}
                      id={`${filterGroup}-${value}`}
                      checked={filter[filterGroup][value] ? filter[filterGroup][value] : false}
                      onChange={(event) => handleExclusivity(event, filterGroup, value)}
                    />
                    <label className='form-check-label' htmlFor={`${filterGroup}-${value}`}>
                      {value}
                    </label>
                    <label className='filter-count-label'> {`\xa0(${resultCount})`} </label>
                  </div>
                );
              })}
            </div>
            <a
              data-toggle='collapse'
              href={`#collapse-${filterGroup}`}
              role='button'
              onClick={toggleCollapsed}
            >
              {collapsed ? `Toon ${nItems - showItems} meer...` : `Toon minder...`}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const FilterSideBar = ({ filter, updateFilter, resetFilter, algorithmDataModel, algorithms }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  //render a side column with selected filter and their options from the defined algorithm data model
  return (
    <div className='col-sm-4'>
      <div>
        <span className='d-flex'>
          <span
            onClick={toggleCollapse}
            data-toggle='collapse'
            href={`#collapse-filter-col`}
            role='button'
          >
            <Icon
              name={collapsed ? 'chevron-forward' : 'chevron-down'}
              style={{ width: '1.5rem', height: '1.5rem' }}
            />
            <span className='h4'>Filters</span>
          </span>
          <span className='h6 mt-2 ml-auto' role='button' onClick={resetFilter}>
            Reset
          </span>
        </span>
        <form className='collapse show mt-2' id='collapse-filter-col'>
          {Object.keys(filter).map((v) => {
            return (
              <FilterBlock
                key={v}
                filter={filter}
                filterGroup={v}
                filterItem={algorithmDataModel[v]}
                updateFilter={updateFilter}
                algorithms={algorithms}
              ></FilterBlock>
            );
          })}
        </form>
      </div>
    </div>
  );
};

const Algorithms = ({ user, algorithmDataModel }) => {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [algorithms, setAlgorithms] = useState([]);
  // const [algorithmDataModel,setAlgorithmDataModel] = useState({});
  const [filteredAlgorithms, setFilteredAlgorithms] = useState({ count: 0, data: [] });

  const [searchQuery, setSearchQuery] = useState('');
  const [lastAction, setLastAction] = useState('');
  const [filter, setFilter] = useState({});
  const fabItems = [
    {
      isPrimary: true,
      iconName: 'fa-plus',
      title: 'Voeg nieuw algoritme toe',
      action: () => {
        history.push('/algorithm/new');
      },
      // to: '/algorithm/new',
    },
    // {
    //   isPrimary: true,
    //   iconName: 'fa-bars',
    //   action: () => {},
    // },
    // {
    //   isPrimary: false,
    //   iconName: 'fa-info-circle',
    //   action: () => {
    //     addHelperText('fa-info-circle', 'Help', 'Hoofdpagina help tekst');
    //   },
    // },
    // {
    //   isPrimary: false,
    //   iconName: 'fa-question-circle',
    //   action: () => {
    //     addHelperText('fa-question-circle', 'Help', 'Meer info...');
    //   },
    // },
  ];

  const pageSize = 10;

  useEffect(() => {
    let submitted = true;
    function loadAlgorithms() {
      return new Promise((resolve, reject) => {
        try {
          getAlgorithms()
            .then((response) => {
              resolve({ algorithms: response.data });
            })
            .catch((err) => reject(err));
        } catch (err) {
          reject(err);
        }
      });
    }
    loadAlgorithms()
      .then(({ algorithms }) => {
        if (submitted) {
          setAlgorithms(algorithms);

          let filter = {};
          for (let key of Object.keys(algorithmDataModel)) {
            if (algorithmDataModel[key].isFilterable) {
              filter[key] = [];
            }
          }
          setFilter(filter);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          toast.error('Je moet ingelogd zijn om algoritmes te bekijken.');
        }
        if (err.response && err.response.status === 403) {
          toast.warn('Dit account is nog in afwachting van goedkeuring, functionaliteit beperkt.', {
            autoClose: false,
          });
        }
      });
    return () => {
      submitted = false;
    };
  }, [algorithmDataModel]);

  useEffect(() => {
    pageData();
  }, [algorithms, currentPage, filter, searchQuery]);

  const pageData = () => {
    let filteredAlgorithms = [...algorithms];

    for (let filterKey of Object.keys(filter)) {
      let mask = Object.entries(filter[filterKey])
        .filter((v) => v[1])
        .map((v) => v[0]);
      if (mask.length) {
        if (algorithmDataModel[filterKey].isMultiSelect) {
          filteredAlgorithms = filteredAlgorithms.filter((a) =>
            mask.some((r) => a[filterKey].includes(r)),
          );
        } else {
          filteredAlgorithms = filteredAlgorithms.filter(
            (algorithm) => mask.indexOf(algorithm[filterKey]) > -1,
          );
        }
      }
    }
    //perform search
    filteredAlgorithms = filteredAlgorithms.filter(
      (m) =>
        m.description.toLowerCase().indexOf(searchQuery) > -1 ||
        m.algorithmName.toLowerCase().indexOf(searchQuery) > -1 ||
        m.algorithmType.toLowerCase().indexOf(searchQuery) > -1,
    );

    const posts =
      lastAction === 'filter'
        ? paginate(filteredAlgorithms, 1, pageSize)
        : paginate(filteredAlgorithms, currentPage, pageSize);
    setFilteredAlgorithms({ count: filteredAlgorithms.length, data: posts });
  };

  const handlePageChange = (page) => {
    if (page === 'First') {
      page = 1;
    }
    if (page == 'Last') {
      page = -1;
    }
    setCurrentPage(page);
    setLastAction('pagechange');
  };

  const search = (query) => {
    if (query.length < 3) {
      query = '';
    }
    setSearchQuery(query.toLowerCase());
  };

  const resetFilter = () => {
    let filter = {};
    for (let key of Object.keys(algorithmDataModel)) {
      if (algorithmDataModel[key].isFilterable) {
        filter[key] = [];
      }
    }
    setFilter(filter);
    setLastAction('filter');
  };

  const updateFilter = ({ group, value, checked }) => {
    // let fg = target.getAttribute("filtergroup");
    // let fv = target.getAttribute("filtervalue");

    // if(AlgorithmModel[fg].isExclusive){
    //     if(filter[fg].indexOf(fv)>-1){
    //         filter[fg] = [];
    //     }else{
    //         filter[fg] = [fv];
    //     }
    // }else{
    //     if(filter[fg].indexOf(fv)>-1){
    //         filter[fg].splice(filter[fg].indexOf(fv),1);
    //     } else{
    //         filter[fg].push(fv);
    //     }
    // }
    let tempFilter = { ...filter };
    tempFilter[group][value] = checked;

    setFilter(tempFilter);
    setLastAction('filter');
  };

  return (
    <div className='page'>
      <div
        className='cover-image'
        style={{
          backgroundImage: `url(${CoverImage})`,
          height: '60vh',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
        }}
      >
        <div className='mask' style={{ height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.25)' }}>
          <div className='d-flex justify-content-center align-items-center h-100 ml-2'>
            <div className='text-white'>
              <h1 className='display-4 mb-3'>AI Inventarisatie in de zorg</h1>
              <div className='h4 lead mb-3'>
                Inventarisatie van AI toepassingen in de zorg op initiatief van het Kennisnetwerk{' '}
                <span className='strong'>AI implementatie in de zorg</span>.
              </div>
              <div className='h4 lead mb-3'>
                Informatie voor en door iedereen die in een ziekenhuis in Nederland betrokken is bij
                het gebruik van <span className='strong'>AI in de zorg</span> <br />
                of overweegt een AI toepassing te gaan gebruiken of te (laten) ontwikkelen.
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='container mt-5'>
        <NotificationContainer userIsAdmin={user && user.isAdmin} />
        <div className='row my-1'>
          <FilterSideBar
            filter={filter}
            updateFilter={updateFilter}
            resetFilter={resetFilter}
            algorithmDataModel={algorithmDataModel}
            algorithms={algorithms}
          />
          <div className='col-sm-8'>
            <FAB items={fabItems}></FAB>
            <SearchBar name='inputSearch' label='Zoeken' searchBarCallBack={search} />
            <div className='h5'>{`Resultaten: ${filteredAlgorithms.count} algoritme${
              filteredAlgorithms.count != 1 ? 's' : ''
            }`}</div>
            <div className='row'>
              <div className='col'>
                {filteredAlgorithms.data.map((algo) => {
                  return (
                    <div key={algo._id} className='card my-2 block-shadow'>
                      <div className='card-header text-uppercase blue-block'>
                        <div className='d-flex'>
                          <div className='py-1'>{algo.algorithmName}</div>
                        </div>
                      </div>

                      <div className='card-body'>
                        <div className='h6 card-text' href='#'>
                          Toegevoegd door {algo.author.name} op{' '}
                          {new Date(algo.timestamp).toLocaleDateString()}
                        </div>
                        <p className='card-text'>{truncateString(algo.description, 255)}</p>
                      </div>
                      <div className='footer mt-3 p-2'>
                        <span className='float-left'>
                          <span>
                            {algo._id && (
                              <Link
                                className='btn btn-register'
                                to={{ pathname: `/algorithm/${algo._id}/detail` }}
                                onClick={() => {
                                  window.scroll(0, 0);
                                }}
                              >
                                Meer lezen
                              </Link>
                            )}
                          </span>
                          {user && (user.isAdmin || user.oid === algo.author._id) && (
                            <span>
                              <Link
                                className='btn btn-register ml-2'
                                to={`/algorithm/${algo._id}/edit`}
                              >
                                Bewerken
                              </Link>
                            </span>
                          )}
                          {user && user.isAdmin && (
                            <span>
                              <Link
                                className='btn btn-register ml-2'
                                to={`/algorithm/${algo._id}/delete`}
                              >
                                Verwijderen
                              </Link>
                            </span>
                          )}
                        </span>
                        <span className='float-right'>
                          <span className='text-uppercase text-underline'>
                            Type: {algo.algorithmType ? algo.algorithmType : 'Niet gespecificeerd'}
                          </span>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <Pagination
                itemsCount={filteredAlgorithms.count}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Algorithms;

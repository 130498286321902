import http from './httpService';

const apiEndpoint = `/api/users`;

export const getUser = async (_id) => {
  const { data } = await http.get(`${apiEndpoint}/${_id}`);
  return data;
};

export const requestUserMigration = async (email) => {
  const { data } = await http.post(`${apiEndpoint}/requestmigrate`, { email });
  return data;
};

export const requestPasswordReset = async (email) => {
  const { data } = await http.post(`/api/auth/resetpassword`, { email });
  return data;
};

export const migrateUser = async (email, token) => {
  const { data } = await http.post(`${apiEndpoint}/migrate`, { email, token });
  return data;
};

export function putChangePassword(password, token, email) {
  return http.put(`/api/auth/changepassword`, { password, token, email });
}

export const verifyUser = async (token, email) => {
  const { data } = await http.post('/api/auth/verify', { token, email });
  return data;
};

export const reverify = async (email) => {
  const { data } = await http.post('/api/auth/reverify', { email });
  return data;
};

export function getUsers() {
  return http.get(apiEndpoint);
}

export function saveUsers(data) {
  return http.put(`/api/users/`, { users: data });
}

export function register(user) {
  return http.post(`/api/auth`, {
    email: user.username,
    password: user.password,
    name: user.name,
    captchaToken: user.captchaToken,
  });
}

import React, { Component } from 'react';
import Form from './form';
import Joi from 'joi-browser';
import * as userService from '../../services/userService';
import auth from '../../services/authService';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

class RegisterForm extends Form {
  state = {
    data: { username: '', password: '', name: '', captchaToken: '' },
    errors: {},
    migrationPossible: false,
  };

  RECAPTCHA_CLIENT_SITE_KEY = process.env.REACT_APP_CLIENT_SITE_KEY;

  schema = {
    username: Joi.string().email().required().label('Email address'),
    password: Joi.string().required().min(5).label('Password'),
    name: Joi.string().required().min(5).label('Name'),
    captchaToken: Joi.string().required(),
  };

  doSubmit = async () => {
    console.log(this.state);

    try {
      const { username, password, name, captchaToken } = this.state.data;
      const response = await userService.register({ username, password, name, captchaToken });
      auth.loginWithJWT(response.headers['x-auth-token']);
      toast.success('Account succesvol aangevraagd. Check je email.');

      setTimeout(() => {
        window.location.replace('/');
      }, 3000);
    } catch (ex) {
      console.log(ex);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors['username'] = 'Emailadres reeds geregistreerd';
        this.setState({ errors });
      } else if (ex.response && ex.response.status === 403) {
        toast.error(ex.response.data.error);

        return;
      } else if (ex.response.status === 449) {
        this.setState({ migrationPossible: true });
      }
    }
  };

  handleCaptchaChange = (value) => {
    const d = { ...this.state.data };
    d.captchaToken = value;
    this.setState({ data: d });
  };

  render() {
    const { migrationPossible } = this.state;
    return (
      <div className='container mb-2'>
        <h1>Vraag een account aan</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput('username', 'Email')}

          {migrationPossible && (
            <>
              <div className='alert alert-info'>
                {`'${this.state.data.username}' had eerder algoritmes geregistreerd, klik op 'migreer algoritmes' om ze
                over te zetten naar je account.`}
              </div>
              <Link className='btn btn-primary' to={{ pathname: `/migrate/` }}>
                Migreer algoritmes
              </Link>
            </>
          )}
          {this.renderPassword('password', 'Wachtwoord')}
          {this.renderInput('name', 'Naam')}
          {this.renderButton('Aanvragen')}
        </form>

        <div className='mt-5'>
          <ReCAPTCHA sitekey={this.RECAPTCHA_CLIENT_SITE_KEY} onChange={this.handleCaptchaChange} />
          {this.state.errors['captchaToken'] && (
            <div className='alert alert-danger'>Captcha onjuist</div>
          )}
        </div>
      </div>
    );
  }
}

export default RegisterForm;

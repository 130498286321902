import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';

export const useQueryParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => {
    const params = new URLSearchParams(search);
    const queryParams = {};

    params.forEach((value, key) => {
      queryParams[key] = value;
    });

    return queryParams;
  }, [search]);
};

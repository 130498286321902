import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  getNotifications,
  Notification,
  deleteNotification,
} from '../services/notificationService';
import Icon from './common/icon';
import { toast } from 'react-toastify';

type NotificationItemsProps = {
  notification: Notification;
  onDelete: (id: string) => void;
  userIsAdmin: boolean;
};

const NotificationItem = ({ notification, onDelete, userIsAdmin }: NotificationItemsProps) => {
  return (
    <div className={`alert alert-${notification.style}`} role='alert'>
      <div className='h6 float-right'>
        Door {notification.author.name} op {new Date(notification.timestamp).toLocaleDateString()}{' '}
        om {new Date(notification.timestamp).toLocaleTimeString()}
      </div>
      <div>
        {notification.paragraphs.map((p, i) => (
          <p key={i}>{p}</p>
        ))}
      </div>
      {userIsAdmin && (
        <div>
          <button className='btn btn-danger m-2' onClick={() => onDelete(notification._id)}>
            Delete notification
          </button>
        </div>
      )}
    </div>
  );
};

const NotificationContainer: React.FC = ({ userIsAdmin }: { userIsAdmin: boolean }) => {
  const [isOpen, setIsOpen] = useState(true); // Manage open/close state
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const toggleNotification = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let valid = true;

    const _getNotifications = async () => {
      try {
        const response: AxiosResponse<Notification[]> = await getNotifications(); // Explicitly type the response
        if (valid) {
          setNotifications(response.data); // Use the data property
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    _getNotifications();

    return () => {
      valid = false;
    };
  }, []);

  const onDelete = async (_id: string) => {
    try {
      const response: AxiosResponse<Notification[]> = await deleteNotification(_id); // Explicitly type the response
      setNotifications((nots) => [...nots.filter((n) => n._id != _id)]);
      toast.success(`Notification with ${_id} removed`);
    } catch (e) {
      console.log(e);
      toast.error('An error occured, check console for more details.');
    }
  };
  return notifications.length === 0 ? (
    <></>
  ) : (
    <div
      className={`notification-box rounded border border-dark p-4 blue-block ${
        isOpen ? 'text-light' : 'text-light'
      }`}
    >
      <div className='d-flex justify-content-between ' onClick={toggleNotification}>
        <div className='h5 mb-2'>Mededelingen:</div>
        <div className='float-right'>
          {isOpen ? <Icon name='caret-up' /> : <Icon name='caret-down' />}
        </div>
      </div>
      <div>
        {isOpen &&
          notifications.map((notification) => {
            return (
              <NotificationItem
                notification={notification}
                key={notification._id}
                onDelete={onDelete}
                userIsAdmin={userIsAdmin}
              />
            );
          })}
      </div>
    </div>
  );
};

export default NotificationContainer;

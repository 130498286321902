import http from './httpService';

const apiEndpoint = `/api/messages`;

function getMessageUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getMessage(id) {
  return http.get(getMessageUrl(id));
}

export function getMessages() {
  return http.get(apiEndpoint);
}

export function postMessage(message) {
  return http.post(apiEndpoint, message);
}

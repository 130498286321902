import http from './httpService';
import { AxiosResponse } from 'axios';

const apiEndpoint = `/api/notifications`;

export type Notification = {
  _id: string;
  paragraphs: Array<string>;
  style: string;
  timestamp: number;
  author: {
    _id: string;
    name: string;
  };
};

export const getNotification = async (id: string): Promise<AxiosResponse<Notification>> => {
  return await http.get(`${apiEndpoint}/${id}`);
};

export const addNotification = async ({
  paragraphs,
  style,
}: {
  paragraphs: Array<string>;
  style: string;
}): Promise<AxiosResponse<Notification[]>> => {
  return await http.post(apiEndpoint, { paragraphs, style });
};

export const deleteNotification = async (id: string): Promise<AxiosResponse<Notification[]>> => {
  return await http.delete(`${apiEndpoint}/${id}`);
};

export const getNotifications = async (): Promise<AxiosResponse<Notification[]>> => {
  return await http.get(apiEndpoint);
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const Pagination = ({ itemsCount, pageSize, onPageChange, currentPage }) => {
  const [pages, setPages] = useState([]);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    const pagesCount = Math.ceil(itemsCount / pageSize);
    if (currentPage === -1) {
      currentPage = pagesCount;
    }

    let pages = [];

    if (pagesCount > 3) {
      pages = [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
      pages = pages.filter((p) => p > 0 && p < pagesCount + 1);
      pages.splice(0, 0, 'First');
      pages.push('Last');
    } else {
      for (let i = 0; i < pagesCount; i++) {
        pages.push(i + 1);
      }
    }
    setPages(pages);
    setActivePage(currentPage);
  }, [itemsCount, currentPage]);

  return (
    <nav>
      <ul className='pagination'>
        {pages.map((p) => (
          <li key={p} className={p === activePage ? 'page-item active' : 'page-item'}>
            <a onClick={() => onPageChange(p)} className='page-link'>
              {p}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;

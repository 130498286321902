import React from 'react';
import { HelpIcon } from '../common/helpIcon.jsx';
//input component for funcional based components
export const FunctionalInput = ({
  name,
  label,
  error,
  type = 'text',
  help = undefined,
  onChange = () => {
    return;
  },
  ...rest
}) => {
  return (
    <div className={`form-group`}>
      <label htmlFor={name}>{label}</label>
      {help && <HelpIcon helpText={help} />}

      <input
        type={type}
        name={name}
        id={name}
        className='form-control'
        onChange={onChange}
        {...rest}
      />
      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import {
  requestPasswordReset,
  putChangePassword,
  verifyUser,
  reverify,
} from '../../services/userService.js';
import { toast } from 'react-toastify';
import Joi from 'joi-browser';
import { FunctionalInput } from '../common/FunctionalInput.jsx';
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';
import { useQueryParams } from '../../utils/useUrlParams.js';
import ReCAPTCHA from 'react-google-recaptcha';

const VerifyUser = () => {
  const [formData, setFormData] = useState({ captcha: '' });
  const [errors, setErrors] = useState({ captcha: '' });
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [userVerified, setUserVerified] = useState(false);
  const { token, email } = useQueryParams();

  const [verificationSent, setVerificationSent] = useState(false);
  const RECAPTCHA_CLIENT_SITE_KEY = process.env.REACT_APP_CLIENT_SITE_KEY;

  const verifyAccount = async () => {
    if (!token || !email || !formData['captcha']) {
      console.log(`Cant submit data incomplete or invalid.`);
      return;
    }
    try {
      await verifyUser(token, email);
      toast.success('Email verificatie compleet');
      setUserVerified(true);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 409) {
        setUserVerified(true);
        return toast.info('Gebruiker email reeds geverifieerd');
      }
      toast.error('Verificatie mislukt. Probeer het opnieuw.');
      setVerificationFailed(true);
    }
  };

  const doSubmit = async () => {
    if (!formData.captcha) {
      console.log(`Cant submit captcha invalid`);
      return;
    }
    try {
      await reverify(email);
      setVerificationSent(true);
    } catch (e) {
      console.log(e);
      toast.error(e);
      setVerificationSent(false);
    }
  };

  const handleCaptchaChange = (value) => {
    const d = { ...formData };
    d.captcha = value;
    setFormData(d);
  };

  const VerificationFailed = () => {
    return (
      <div>
        <div className='d-flex mt-3'>
          <i
            className='fa fa-exclamation-circle fa-4x'
            style={{ color: 'red' }}
            aria-hidden='true'
          ></i>
          <div className='text-danger ml-3'>
            Helaas er is iets misgegaan bij de verificatie van je email. Gebruik de knop onderaan de
            pagina om de verificatie email nogmaals te sturen.
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='container'>
      <div className='h1'>Email verificatie</div>
      <div>
        Alvorens gebruik te kunnen maken van het AI inventaris, dient u uw email te verifiëren.
      </div>
      <div>Gebruiker geverifieerd:{userVerified ? <strong>Ja</strong> : <strong>Nee</strong>}</div>
      {verificationFailed && <VerificationFailed />}
      {!verificationSent && !userVerified ? (
        <div>
          <div>
            <ReCAPTCHA sitekey={RECAPTCHA_CLIENT_SITE_KEY} onChange={handleCaptchaChange} />
            {errors['captcha'] && <div className='alert alert-danger'>Captcha onjuist</div>}
          </div>
          <div className='btn btn-primary mt-2' onClick={verifyAccount}>
            Activeer account
          </div>
          <div className='mt-5'>Geen email gehad? Vraag een nieuwe verificatie mail aan:</div>
          <div className='btn btn-primary' onClick={doSubmit}>
            Stuur verificatie email opnieuw
          </div>
        </div>
      ) : (
        !userVerified && <div>Email verstuurd. Check ook uw junk folders.</div>
      )}
    </div>
  );
};

const PasswordReset = () => {
  const { token, email } = useQueryParams();

  const [data, setData] = useState({ email: '', password: '', password_v: '' });
  const [errors, setErrors] = useState({ email: '', password: '', password_v: '' });
  const [emailSent, setEmailSent] = useState(false);

  const [passwordChanged, setPasswordChanged] = useState(false);

  useEffect(() => {
    const errors = validate();
    setErrors(errors);
  }, [data]);

  const schema = {
    email: Joi.string().email().required().label('Email address'),
    password: Joi.string().min(5).required().label('Password').allow(''),
    password_v: Joi.string().allow(''),
  };

  const hasData = () => {
    for (let k of Object.keys(data)) {
      if (data[k].length > 0) {
        return true;
      }
    }
    return false;
  };

  const validate = () => {
    const errors = {};

    if (!hasData()) {
      return errors;
    }

    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) {
      return errors;
    }

    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }

    if (data.password != data.password_v) {
      errors.password = 'Passwords dont match';
    }

    return errors;
  };

  const handleChange = ({ target }) => {
    const key = target.getAttribute('name');
    let temp = { ...data };
    temp[key] = target.value;
    setData(temp);
  };

  const submitForm = async () => {
    if (Object.keys(errors).length > 0) {
      console.log({ errors });
      return;
    }
    try {
      const result = await requestPasswordReset(data.email);
      console.log(result);
      toast.success('Email sent.');
      setEmailSent(true);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data) {
        toast.error(e.response.data);
      } else {
        toast.error('Unknown error');
      }
    }
  };

  const changePassword = async () => {
    try {
      const result = await putChangePassword(data.password, token, email);
      console.log(result);
      toast.success('Password was updated. Please sign in again.');
      setPasswordChanged(true);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data) {
        toast.error(e.response.data);
      } else {
        toast.error('Unknown error');
      }
    }
  };

  return (
    <>
      <div className='container'>
        <h3>Nieuw wachtwoord aanvragen</h3>
        <form>
          {!emailSent && !token ? (
            <>
              <FunctionalInput
                name='email'
                placeholder='Enter email...'
                label='E-mail'
                error={errors.email}
                onChange={handleChange}
              />
              <div className='btn btn-primary' onClick={submitForm}>
                Send reset password email
              </div>
            </>
          ) : !token ? (
            <>
              <div>Een herstellink is verstuurd naar uw emailadres. Check ook uw junk folders.</div>
            </>
          ) : !passwordChanged ? (
            <>
              <form>
                <FunctionalInput
                  type='password'
                  name='password'
                  placeholder='Enter new password...'
                  label='Password'
                  error={errors.password}
                  onChange={handleChange}
                />
                <FunctionalInput
                  type='password'
                  name='password_v'
                  placeholder='Enter new password again...'
                  label='Password (to verify)'
                  error={errors.password_v}
                  onChange={handleChange}
                />
                <div className='btn btn-primary' onClick={changePassword}>
                  Change password
                </div>
              </form>
            </>
          ) : (
            <div>
              Password changed, please <Link to='/login'>sign in</Link> again.
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export { PasswordReset, VerifyUser };

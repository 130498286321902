import Joi from 'joi-browser';
import React from 'react';
import { toast } from 'react-toastify';
import Form from './common/form';
import '../css/algorithmform.css';

import {
  deleteAlgorithm,
  getAlgorithm,
  getAlgorithmDataModel,
  saveAlgorithm,
} from '../services/algorithmService.js';
import FAB from './common/fab';

class AlgorithmForm extends Form {
  state = {
    data: {
      algorithmName: '',
      description: '',
      activeHospital: '',
      medicalSpecialism: '',

      intendedPurpose: '',
      applicationGoal: [],
      intendedUser: [],
      benefitClaims: '',
      targetPopulation: [],

      riskClass :'',

      algorithmFunction: '',
      algorithmType: '',
      medicalDevice: '',
      primaryDeveloper: '',
      developerName: '',

      technicalReadinessLevel: '',
      dataSource: '',
      technicalPlatform: '',
      dataStandard: '',
      innovationFunnelTested: '',
      collaboration: '',
      peopleInvolved: [],
      lessonsLearned: '',
      nameCommercialProduct: '',
      reasonCancellation: '',
      mediaURL: '',
      contactEmail:'',
      toDelete: '',
    },
    dataModel: {},
    errors: {},
    id: -1,
    savedAlgo: false,
    fabItems: [
      {
        isPrimary: true,
        iconName: 'fa-floppy-o',
        title: 'Sla je werk op (in deze browser)',
        action: () => {
          this.saveToLocalStorage();
        },
      },
      {
        isPrimary: false,
        iconName: 'fa-arrow-left',
        title: 'Ga terug naar het overzicht',
        action: () => {
          window.location = '/';
        },
      },
      {
        isPrimary: false,
        iconName: 'fa-question-circle',
        action: () => {
          console.log('clicked fa-question-circle');
        },
      },
    ],
  };

  saveToLocalStorage = () => {
    try {
      const { data: algorithm } = this.state;
      localStorage.setItem(`algorithm`, JSON.stringify(algorithm));
    } catch (err) {
      toast.error('Helaas er ging iets mis bij het opslaan.');
      return;
    }
    toast.success('Formulier is tussentijds opgeslagen!');
  };

  async populateForm() {
    try {
      const algorithmId = this.props.match.params.id;
      if (algorithmId === 'new') {
        const algo = localStorage.getItem(`algorithm`);
        this.setState({ savedAlgo: algo != null });
        return;
      }
      const { data: algorithm } = await getAlgorithm(algorithmId);
      this.setState({ data: this.mapToViewModel(algorithm), id: algorithmId });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        this.props.history.replace('/not-found');
      }
    }
  }

  setSchema(dataModel) {
    this.schema = {
      _id: Joi.string(),
      algorithmName: Joi.string().required().min(5).max(150).label('Naam van algoritme'),
      description: Joi.string().min(25).max(512).label('Korte beschrijving').allow(null, '', 'NA'),
      algorithmFunction: Joi.any()
        .valid(dataModel.algorithmFunction.listData)
        .label('Algoritme functie')
        .allow(null, '', 'NA'),
      algorithmType: Joi.string()
        .valid(dataModel.algorithmType.listData)
        .label('Algoritme type')
        .allow(null, '', 'NA'),
      benefitClaims: Joi.string().min(5).max(512).label('Toegevoegde waarde').allow(null, '', 'NA'),

      activeHospital: Joi.string()
        .valid(dataModel.activeHospital.listData)
        .label('Ziekenhuis')
        .allow(null, '', 'NA'),
      medicalSpecialism: Joi.string()
        .valid(dataModel.medicalSpecialism.listData)
        .label('Specialisme')
        .allow(null, '', 'NA'),
      collaboration: Joi.string().max(100).label('Samenwerking').allow(null, '', 'NA'),
      intendedPurpose: Joi.string().min(25).max(2048).label('Beoogd gebruik').allow(null, '', 'NA'),

      applicationGoal: Joi.array()
        .items(Joi.string().valid(dataModel.applicationGoal.listData))
        .label('Doel van toepassing'),
      intendedUser: Joi.array()
        .items(Joi.string().valid(dataModel.intendedUser.listData))
        .label('Eindgebruikers'),

      targetPopulation: Joi.array().items(Joi.string().valid(dataModel.targetPopulation.listData))
        .label('Beoogde populatie'),
      medicalDevice: Joi.string()
        .valid(dataModel.medicalDevice.listData)
        .label('Medisch hulpmiddel')
        .allow(null, '', 'NA'),
      riskClass: Joi.string()
        .valid(dataModel.riskClass.listData)
        .label('Risk Class')
        .allow(null, '', 'NA'),
      primaryDeveloper: Joi.string()
        .valid(dataModel.primaryDeveloper.listData)
        .label('Ontwikkelende partij')
        .allow(null, '', 'NA'),
      developerName: Joi.string().min(5).max(255).label('Naam ontwikkelaar').allow(null, '', 'NA'),
      lessonsLearned: Joi.string().min(5).max(512).label('Opgedane ervaring').allow(null, '', 'NA'),
      technicalReadinessLevel: Joi.string()
        .valid(dataModel.technicalReadinessLevel.listData)
        .label('Readiness Level')
        .allow(null, '', 'NA'),
      dataSource: Joi.any()
        .valid(dataModel.dataSource.listData)
        .label('Databron')
        .allow(null, '', 'NA'),
      technicalPlatform: Joi.any()
        .valid(dataModel.technicalPlatform.listData)
        .label('Platform')
        .allow(null, '', 'NA'),
      dataStandard: Joi.any()
        .valid(dataModel.dataStandard.listData)
        .label('Datastandaard')
        .allow(null, '', 'NA'),
      innovationFunnelTested: Joi.any()
        .valid(dataModel.innovationFunnelTested.listData)
        .label('Innovation funnel getoetst')
        .allow(null, '', 'NA'),
      peopleInvolved: Joi.array()
        .items(Joi.string().valid(dataModel.peopleInvolved.listData))
        .label('Betrokken personen'),
      nameCommercialProduct: Joi.string().min(3).max(50).allow(null, '', 'NA'),
      reasonCancellation: Joi.string().max(100).allow(null, '', 'NA'),
      mediaURL: Joi.string().uri().allow(null, '', 'NA'),
      contactEmail:Joi.string().email().allow(null,'','NA'),
      // algorithmVersion:Joi.string().min(2).max(25).required().label("Algorithm Version"),
      // algorithmVersionDate:Joi.date().max('now').required().label("Algorithm Version Date"),
      // emailDeveloper:Joi.string().email().required().label("Email address Developer"),

      // trainingDataOrigin:Joi.any().valid(dataModel.activeHospital.listData).required().label("Origin of training data"),
      // clinicalTrialId:Joi.string().allow(null, '').label("Clinical Trial Id"),
      // clinicalTrialURL:Joi.string().uri().allow(null, '').label("Clinical Trial URL"),
      // eudamedId:Joi.string().allow(null, '').label("EUDAMED Id"),
      // eudamedURL:Joi.string().uri().allow(null, '').label("EURDAMED URL"),
      // doi:Joi.string().uri().allow(null, '').label("DOI of publication"),
      // publicationReference:Joi.string().uri().allow(null, '').label("Publication Reference"),
      // mediaURL:Joi.string().uri().allow(null, '').label("Media URL"),
      // mediaTitle:Joi.string().allow(null, '').label("Media Title"),
      toDelete: Joi.string().allow('').optional(),
    };
  }

  getProperSubmitString(){
    return document.location.href.slice(-4)==='edit'?'Updaten':'Toevoegen'
  } 

  async componentDidMount() {
    const { data: dataModel } = await getAlgorithmDataModel();
    this.setState({ dataModel: dataModel });
    this.setSchema(dataModel);
    await this.populateForm();
  }

  doSubmit = async () => {
    //call server
    
    const initialAlgo = { ...this.state.data };
    const user = this.props.user;
    if (!user) {
      toast.error('Je moet ingelogd zijn om algoritmes toe te voegen');
      return;
    }

    initialAlgo.author = {
      _id:user._id,
      name:user.name
    };

    //remove empty values
    const algorithm = Object.entries(initialAlgo).reduce(
      (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
      {},
    );

    try {
      await saveAlgorithm(algorithm, this.state.id);
      this.props.history.push('/');
      if (this.state.id === -1) {
        toast.success(`Algoritme '${algorithm.algorithmName}' toegevoegd aan het register.`);
      } else {
        toast.success(`Algoritme '${algorithm.algorithmName}' is aangepast.`);
      }
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 403) {
        toast.error(
          `Je bent niet geautoriseerde om deze actie uit te voeren: ${ex.response.data}.`,
        );
      } else if (ex.response && ex.response.status === 400) {
        toast.error(`Formulier is niet geldig: '${ex.response.data}'.`);
      } else {
        toast.error('Een onbekende fout heeft zich voorgedaan.');
      }
    }
  };

  mapToViewModel(algo) {
    return {
      algorithmName: algo.algorithmName,
      developerName: algo.developerName,
      medicalSpecialism: algo.medicalSpecialism,
      targetPopulation: algo.targetPopulation,

      technicalReadinessLevel: algo.technicalReadinessLevel,
      activeHospital: algo.activeHospital,
      description: algo.description,

      algorithmType: algo.algorithmType,
      algorithmFunction: algo.algorithmFunction,

      intendedUser: algo.intendedUser,
      intendedPurpose: algo.intendedPurpose,
      applicationGoal: algo.applicationGoal,
      medicalDevice: algo.medicalDevice
        ? 'Ja (medisch hulpmiddel)'
        : 'Nee (geen medisch hulpmiddel)',
      riskClass: algo.riskClass,
      primaryDeveloper: algo.primaryDeveloper,
      nameCommercialProduct: algo.nameCommercialProduct,
      dataSource: algo.dataSource,
      innovationFunnelTested: algo.innovationFunnelTested,
      collaboration: algo.collaboration,
      benefitClaims: algo.benefitClaims,
      dataStandard: algo.dataStandard,
      peopleInvolved: algo.peopleInvolved,
      lessonsLearned: algo.lessonsLearned,
      technicalPlatform: algo.technicalPlatform,
      reasonCancellation: algo.reasonCancellation,
      mediaURL: algo.mediaURL,
      contactEmail:algo.contactEmail
    };
  }

  handleDelete = async () => {
    const { algorithmName, toDelete } = this.state.data;

    if (algorithmName === toDelete) {
      const user = this.props.user;
      if (!user) {
        toast.error('Je moet ingelogd zijn om algoritmes te verwijderen.');
        return;
      }
      try {
        await deleteAlgorithm(this.state.id);
        this.props.history.push('/');
        toast.success(`Algoritme '${algorithmName}' verwijderd uit het register.`);
      } catch (ex) {
        if (ex.response && ex.response.status === 403) {
          toast.error('Je bent niet bevoegd om algoritmes te verwijderen.');
        } else {
          toast.error('Een onbekende fout heeft zich voorgedaan');
          console.log(ex.response);
        }
      }
    }
  };

  fillFromLocalStorage = () => {
    const algorithm = JSON.parse(localStorage.getItem('algorithm'));
    this.setState({ data: this.mapToViewModel(algorithm), savedAlgo: false });
  };

  render() {
    const { data, dataModel, fabItems, savedAlgo } = this.state;
    const { deleteObject } = this.props;

    if (deleteObject) {
      return (
        <div className='container'>
          <form className='m-2'>
            <p className='p-3 mb-2 bg-danger text-white'>{`Je probeert algoritme '${data.algorithmName}' te verwijderen. Typ ter verificatie de naam van het algoritme.`}</p>
            {this.renderInput('toDelete', 'Naam van het algoritme')}
            <div className='btn btn-danger mt-2' onClick={this.handleDelete}>
              VERWIJDEREN
            </div>
          </form>
        </div>
      );
    }
    if (Object.entries(dataModel).length === 0) {
      return <div>Laden...</div>;
    }

    return (
      <div className='container'>
        {savedAlgo && (
          <div className='alert alert-warning alert-dismissible fade show' role='alert'>
            <div>Een eerder gevonden algoritme is gevonden, herladen?</div>
            <div className='btn btn-warning ml-2' onClick={this.fillFromLocalStorage}>
              Ja graag!
            </div>
            <div
              className='btn btn-warning ml-2'
              onClick={() => this.setState({ savedAlgo: false })}
            >
              Nee, start opnieuw.
            </div>
          </div>
        )}

        <h1>Voeg een algoritme toe</h1>
        <p>Om de flexibiliteit van het formulier te bevorderen, zijn er geen velden (behalve de naam) verplicht. Mocht een veld niet van toepassing zijn op uw project, dan kunt u kiezen om het over te slaan.</p>
        <form onSubmit={this.handleSubmit}>
          <div className='h3'>Algoritme details</div>
          {this.renderInput('algorithmName', dataModel.algorithmName.displayName)}
          {this.renderInput('description', dataModel.description.displayName)}
          {this.renderSelect(
            'algorithmFunction',
            dataModel.algorithmFunction.displayName,
            dataModel.algorithmFunction.listData,
          )}
          {this.renderSelect(
            'algorithmType',
            dataModel.algorithmType.displayName,
            dataModel.algorithmType.listData,
            'Selecteer een optie',
            false,
            dataModel.algorithmType.helpInfo,
          )}
          {this.renderInput('benefitClaims', dataModel.benefitClaims.displayName)}

          <div className='h3'>Ziekenhuis en specialisme</div>
          {this.renderSelect(
            'activeHospital',
            dataModel.activeHospital.displayName,
            dataModel.activeHospital.listData,
            'Selecteer een optie',
          )}
          {this.renderSelect(
            'medicalSpecialism',
            dataModel.medicalSpecialism.displayName,
            dataModel.medicalSpecialism.listData,
            'Selecteer een optie',
            false,
            dataModel.medicalSpecialism.helpInfo,
          )}
          {this.renderInput(
            'collaboration',
            'Samenwerkingen',
            'text',
            false,
            false,
            dataModel.collaboration.helpInfo,
          )}

          <div className='h3'>Doel en gebruik</div>
          {this.renderInput('intendedPurpose', dataModel.intendedPurpose.displayName)}
          {this.renderMultiSelect(
            'applicationGoal',
            dataModel.applicationGoal.displayName,
            dataModel.applicationGoal.listData,
          )}
          {this.renderMultiSelect(
            'intendedUser',
            'Eindgebruikers',
            dataModel.intendedUser.listData,
          )}
          {this.renderMultiSelect(
            'targetPopulation',
            dataModel.targetPopulation.displayName,
            dataModel.targetPopulation.listData,
            'Selecteer een optie',
          )}

          {/* {this.renderInput("algorithmVersion","Algorithm Version")}
                {this.renderInput("algorithmVersionDate","Algorithm Version Date")} */}

          <div className='h3'>Ontwikkeling</div>
          {this.renderSelect(
            'medicalDevice',
            'Medisch hulpmiddel',
            dataModel.medicalDevice.listData,
          )}
          {data.medicalDevice == dataModel.medicalDevice.listData[0] &&
            this.renderSelect(
              'riskClass',
              dataModel.riskClass.displayName,
              dataModel.riskClass.listData,
              'Selecteer een optie',
              true,
            )}
          {this.renderSelect(
            'primaryDeveloper',
            dataModel.primaryDeveloper.displayName,
            dataModel.primaryDeveloper.listData,
            'Selecteer een optie',
          )}
          {dataModel.primaryDeveloper.listData.slice(0, 1).indexOf(data.primaryDeveloper) > -1 &&
            this.renderInput(
              'nameCommercialProduct',
              dataModel.nameCommercialProduct.displayName,
              'text',
              false,
              true,
            )}
          {this.renderInput('developerName', 'Naam ontwikkelaar')}
          {this.renderMultiSelect(
            'peopleInvolved',
            'Betrokken personen',
            dataModel.peopleInvolved.listData,
          )}
          {this.renderInput(
            'lessonsLearned',
            dataModel.lessonsLearned.displayName,
            'text',
            false,
            false,
            dataModel.lessonsLearned.helpInfo
          )}

          <div className='h3'>Data, fase en platform</div>
          {this.renderSelect(
            'technicalReadinessLevel',
            dataModel.technicalReadinessLevel.displayName,
            dataModel.technicalReadinessLevel.listData,
            'Selecteer een optie',false,dataModel.technicalReadinessLevel.helpInfo

          )}          
          {data.technicalReadinessLevel === 'Out of service' &&
            this.renderInput(
              'reasonCancellation',
              'Reden van buitengebruikstelling',
              'text',
              false,
              true,
            )}
          {this.renderSelect(
            'innovationFunnelTested',
            dataModel.innovationFunnelTested.displayName,
            dataModel.innovationFunnelTested.listData,
            'Selecteer een optie', false, dataModel.innovationFunnelTested.helpInfo
          )}
          {this.renderSelect(
            'dataSource',
            dataModel.dataSource.displayName,
            dataModel.dataSource.listData,
            'Selecteer een optie',
          )}
          {this.renderSelect(
            'technicalPlatform',
            dataModel.technicalPlatform.displayName,
            dataModel.technicalPlatform.listData,
            'Selecteer een optie',
          )}
          {this.renderSelect(
            'dataStandard',
            dataModel.dataStandard.displayName,
            dataModel.dataStandard.listData,
            'Selecteer een optie',
          )}
          
          {this.renderInput('mediaURL', 'Externe media link',"text",false,false,dataModel.mediaURL.helpInfo)}
          {this.renderInput('contactEmail', 'Email contact persoon',"text",false,false,dataModel.contactEmail.helpInfo)}


          {/* {this.renderInput("clinicalTrialId", "Trial Id")}
                {this.renderInput("clinicalTrialURL", "Trial URL")}
                {this.renderInput("eudamedId","EUDAMED Id")}
                {this.renderInput("eudamedURL","EURDAMED URL")}
                {this.renderInput("doi", "DOI of publication")}
                {this.renderInput("publicationReference","Publication Reference")}
                {this.renderInput("mediaURL","Media URL")}
                {this.renderInput("mediaTitle","Media Title")}                     */}

          {/* <div className="h3">Personal details</div>                 */}
          {/* {this.renderInput("emailDeveloper","Email address Developer")}                 */}

          {/* <div className="h3">Other details</div> */}

          {/* {this.renderSelect("riskClass","Risk Classification",dataModel.riskClass.listData)} */}
          {/* {this.renderSelect("trainingDataOrigin", "Origin of training data",dataModel.activeHospital.listData)} */}

          {this.renderButton(this.getProperSubmitString())}
        </form>
        <FAB items={fabItems}></FAB>
      </div>
    );
  }
}

// const AlgorithmModel = {
//     activeHospital:{
//         displayName:"Ziekenhuis",
//         listData:hospitals,
//         isExclusive:false
//     },
//     medicalSpecialism:
//     {
//         displayName:"Specialisme",
//         listData:specialisms,
//         isExclusive:false
//     },
//     algorithmType:{
//         displayName:"Type algoritme",
//         listData:algorithmTypes,
//         isExclusive:false
//     },
//     technicalReadinessLevel:{
//         displayName:"Readiness level",
//         listData:technicalReadinessLevels,
//         isExclusive:false
//     },
//     targetPopulation:{
//         displayName:"Beoogde populatie",
//         listData:targetPopulations,
//         isExclusive:true
//     },
//     intendedUse:{
//         displayName:"Beoogd gebruik",
//         listData:intendedUses,
//         isExclusive:false
//     }
// }

// const AlgorithmModel = {};

export default AlgorithmForm;

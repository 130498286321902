import React from 'react';
import { Redirect } from 'react-router-dom';
import Form from './form';
import Joi from 'joi-browser';
import auth from '../../services/authService';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

class LoginForm extends Form {
  state = {
    data: { email: '', password: '', captchaToken: '' },
    errors: { email: '', password: '', captchaToken: '' },
  };

  RECAPTCHA_CLIENT_SITE_KEY = process.env.REACT_APP_CLIENT_SITE_KEY;

  schema = {
    email: Joi.string().required().label('Email'),
    password: Joi.string().required().label('Password'),
    captchaToken: Joi.string().required(),
  };

  doSubmit = async () => {
    try {
      const { email, password, captchaToken } = this.state.data;

      await auth.login(email, password, captchaToken);
      window.location.replace('/');
    } catch (ex) {
      console.log({ ex });
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        toast.error(ex.response.data);
        this.setState({ errors });
      }
      if (ex.response && ex.response.status === 403) {
        toast.error('Invalid captcha. Refresh the page and try again.');
      }
    }
  };

  handleCaptchaChange = (value) => {
    const d = { ...this.state.data };
    d.captchaToken = value;
    this.setState({ data: d });
  };

  render() {
    // if(auth.getCurrentUser())return <Redirect to="/" />
    // const {data,errors}= this.state;
    return (
      <div className='container mb-2'>
        <h1>Login</h1>
        <form onSubmit={this.handleSubmit}>
          {/* <Input label="Password" value={data.password} name="password" onChange={this.handleChange} error={errors.password}/>                */}
          {this.renderInput('email', 'Email')}
          {this.renderInput('password', 'Password', 'password', true)}
          <div>
            Forgotten password? Change it <Link to='/password-reset'>here</Link>
          </div>
        </form>

        <div>
          <ReCAPTCHA sitekey={this.RECAPTCHA_CLIENT_SITE_KEY} onChange={this.handleCaptchaChange} />
          {this.state.errors['captchaToken'] && (
            <div className='alert alert-danger'>Captcha onjuist</div>
          )}
        </div>
        {this.renderButton('Login')}
      </div>
    );
  }
}

export default LoginForm;

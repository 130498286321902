import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Algorithm from './components/algorithm';
import AlgorithmDetail from './components/algorithmDetail.jsx';
import AlgorithmForm from './components/algorithmForm.jsx';
import Footer from './components/common/footer.jsx';
import NavBar from './components/navbar.jsx';
import NotFound from './components/notFound';
import { ContactForm } from './components/pages/contact.jsx';

import Profile from './components/profile';
import Admin from './components/pages/admin.jsx';
import DashBoard from './components/pages/dashboard.jsx';
import auth from './services/authService.js';

import { getAlgorithmDataModel } from './services/algorithmService.js';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoginForm from './components/common/loginForm.jsx';
import RegisterForm from './components/common/registerForm.jsx';
import UserMigration from './components/pages/UserMigration.jsx';
import { PasswordReset, VerifyUser } from './components/pages/PasswordReset.jsx';

const App = () => {
  const [user, setUser] = useState(null);
  const [algorithmDataModel, setAlgorithmDataModel] = useState({});

  const location = useLocation();

  // check if a user is logged in, if not navigate to login page
  useEffect(() => {
    const user = auth.getCurrentUser();
    setUser(user);
    getAlgorithmDataModel().then((resp) => {
      setAlgorithmDataModel(resp.data);
    });
  }, [location]);

  return (
    <>
      <ToastContainer position='bottom-left' />
      <NavBar user={user} />
      <main className='flex-grow-1'>
        {user && !user.allowAccess && (
          <div className='alert alert-warning alert-dismissible fade show'>
            Dit account is nog in afwachting van goedkeuring, functionaliteit beperkt.
          </div>
        )}
        <Switch>
          <Route
            path='/algorithm/:id/detail'
            render={(props) => (
              <AlgorithmDetail algorithmDataModel={algorithmDataModel} {...props} user={user} />
            )}
          />
          <Route
            path='/algorithm/:id/delete'
            render={(props) => (
              <AlgorithmForm
                algorithmDataModel={algorithmDataModel}
                {...props}
                deleteObject={true}
                user={user}
              />
            )}
          />
          <Route
            path='/algorithm/:id/edit'
            render={(props) => (
              <AlgorithmForm algorithmDataModel={algorithmDataModel} {...props} user={user} />
            )}
          />
          <Route
            path='/algorithm/:id'
            exact
            render={(props) => (
              <AlgorithmForm algorithmDataModel={algorithmDataModel} {...props} user={user} />
            )}
          />
          <Route path='/login' render={(props) => <LoginForm {...props} user={user} />} />
          <Route path='/register' render={(props) => <RegisterForm {...props} user={user} />} />
          <Route path='/logout' render={() => auth.logout()} />
          <Route path='/contact' render={(props) => <ContactForm {...props} user={user} />} />
          <Route path='/profile/:id' render={(props) => <Profile {...props} user={user} />} />
          <Route
            path='/admin'
            render={(props) => {
              if (user != null && user.isAdmin) {
                return <Admin {...props} />;
              }
              return <NotFound />;
            }}
          />
          <Route
            path='/dashboard'
            render={(props) => (
              <DashBoard algorithmDataModel={algorithmDataModel} {...props} user={user} />
            )}
          />
          <Route path='/migrate' render={(props) => <UserMigration {...props} user={user} />} />
          <Route
            path='/password-reset/:token'
            render={(props) => <PasswordReset {...props} user={user} />}
          />
          <Route
            path='/password-reset'
            render={(props) => <PasswordReset {...props} user={user} />}
          />
          <Route path='/verify-email' render={(props) => <VerifyUser {...props} user={user} />} />
          <Route
            exact
            path='/'
            render={(props) => (
              <Algorithm algorithmDataModel={algorithmDataModel} {...props} user={user} />
            )}
          />
          <Route path='/not-found' component={NotFound} />
          <Redirect to='/not-found'></Redirect>
        </Switch>
      </main>
      <Footer />
    </>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import { requestUserMigration, migrateUser } from '../../services/userService.js';
import { toast } from 'react-toastify';
import Joi from 'joi-browser';

import { HelpIcon } from '../common/helpIcon.jsx';
import { Link } from 'react-router-dom';

//input component for funcional based components
const ANewInput = ({
  name,
  label,
  error,
  labelSize = '',
  indent = false,
  help = undefined,
  onChange = () => {
    return;
  },
  ...rest
}) => {
  return (
    <div className={`form-group ${indent ? 'ml-2' : ''}`}>
      <label className={labelSize} htmlFor={name}>
        <strong>{label}</strong>
      </label>
      {help && <HelpIcon helpText={help} />}

      <input {...rest} name={name} id={name} className='form-control' onChange={onChange} />
      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  );
};

const UserMigration = ({ user }) => {
  const [data, setData] = useState({ email: '', token: '' });
  const [userdata, setuserdata] = useState(null);
  const [errors, setErrors] = useState({ email: '', token: '' });
  const [userMigrated, setUserMigrated] = useState(false);

  useEffect(() => {
    const errors = validate();
    setErrors(errors);
  }, [data]);

  const schema = {
    email: Joi.string().email().required().label('Email address'),
    token: Joi.string().min(6).max(6).allow(''),
  };

  const validate = () => {
    const errors = {};
    if (data.email.length === 0 && data.token.length === 0) {
      return errors;
    }
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) {
      return errors;
    }

    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleChange = ({ target }) => {
    const key = target.getAttribute('name');
    let temp = { ...data };
    temp[key] = target.value;
    setData(temp);
  };

  const submitForm = async () => {
    if (Object.keys(errors).length > 0) {
      return;
    }
    try {
      const result = await requestUserMigration(data.email);
      console.log(result);
      setuserdata(result);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data) {
        toast.error(e.response.data);
      } else {
        toast.error('Unknown error');
      }
    }
  };

  const submitToken = async () => {
    if (Object.keys(errors).length > 0) {
      return;
    }
    try {
      const result = await migrateUser(data.email, data.token);
      toast.success(
        `User succesfully migrated. ${result.updates} algorithms transfered to your account.`,
      );
      setUserMigrated(true);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data) {
        toast.error(e.response.data);
      } else {
        toast.error('Unknown error');
      }
    }
  };

  return (
    <>
      <div className='container'>
        <h3>Migratie van gebruikers account</h3>
        <p>Dit is de migratiepagina van je gebruikers account.</p>
        {userdata === null ? (
          <div>
            <ANewInput name='email' label='E-mail' error={errors.email} onChange={handleChange} />
            <div className='btn btn-primary' onClick={submitForm}>
              Next
            </div>
          </div>
        ) : (
          <div>
            <p>Een 6-cijferige token is naar het emailadres gestuurd. Vul het hieronder in.</p>
            <ANewInput name='token' label='Token' error={errors.token} onChange={handleChange} />
            <div className='btn btn-primary' onClick={submitToken}>
              Submit
            </div>
          </div>
        )}

        {userMigrated && (
          <div>
            <div>Gebruiker gemigreerd! Verander nu je wachtwoord hieronder:</div>
            <div className='btn btn-primary text-white mt-3'>
              <Link className='text-white' to={`/password-reset`}>
                Wachtwoord instellen
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserMigration;

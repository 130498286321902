import Joi from 'joi-browser';
import React, { useEffect, useState } from 'react';

import AlgorithmTable from './algorithmTable.jsx';
import Input from './common/input.jsx';
import '../css/profile.css';

import { getUserAlgorithms } from '../services/profileService';

import { getUser } from '../services/userService.js';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const useValidateProp = (input, globalSchema) => {
  const name = input.name;
  const value = input.value;
  const obj = { [name]: value };
  const schema = { [name]: globalSchema[name] };
  const { error } = Joi.validate(obj, schema);
  return error ? error.details[0].message : null;
};
const EditableInput = ({ name, value, editMode, handleChange, error }) => {
  return (
    <>
      <div className='col-sm-3'>
        <div className='h6 mb-0'>{name.charAt(0).toUpperCase() + name.slice(1)}</div>
      </div>
      {editMode ? (
        <Input
          value={value}
          name={name}
          onChange={handleChange}
          placeholder={`Type your ${name} here..`}
          error={error}
        />
      ) : (
        <div className='col-sm-9 text-secondary'>{value}</div>
      )}
    </>
  );
};

const Profile = ({ match, user }) => {
  const [userProfileData, setUserProfileData] = useState({
    name: 'Your name',
    imageUrl: '/public/user_placeholder.jpg',
    verified: true,
    email: 'email',
  });
  const [algorithms, setAlgorithms] = useState([]);
  const [errors, setErrors] = useState({
    name: '',
    email: '',
  });

  const [schema] = useState({
    name: Joi.string().min(5).required().label('Name'),
    email: Joi.string().required().email().label('Email'),
  });

  const isValid = useValidateProp;

  useEffect(() => {
    let valid = true;
    const { id } = match.params;

    const queryUserProfileData = async () => {
      // const user = await getUserProfile(id);
      try {
        const userData = await getUser(id);
        console.log({userData})
        const algorithms = await getUserAlgorithms(id);
        if (valid) {
          setAlgorithms(algorithms);
          setUserProfileData(userData);
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 404) {
          toast.error('User not found');

        } else {
          toast.error('Unexpected error.');
        }
      }
    };

    queryUserProfileData();
    return () => {
      valid = false;
    };
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const currentErrors = { ...errors };
    const errorMessage = isValid(input, schema);
    if (errorMessage) {
      currentErrors[input.name] = errorMessage;
    } else {
      delete currentErrors[input.name];
    }
    const temp_data = { ...userProfileData };
    temp_data[input.name] = input.value;
    setUserProfileData(temp_data);
    setErrors(currentErrors);
  };

  console.log({ match, user,userProfileData });

  return (
    <>
      <div className='container'>
        <div className=''>
          Profiel van {userProfileData.name}
          <div className='row gutters-sm'>
            <div className='col col-md-4'>
              <div className='card mb-3'>
                <div className='card-body'>
                  <div className='d-flex flex-column align-items-center text-center'>
                    <img
                      src={userProfileData.imageUrl}
                      alt='profile-image'
                      className='img-fluid rounded-circle'
                    />
                  </div>
                </div>
              </div>
              {user && user._id === match.params.id && (
                <div className='card mb-3'>
                  <div className='card-body'>
                    <div className='h4 card-title'>Instellingen</div>
                    {!userProfileData.verified && (
                      <Link to={`/verify-email/?email=${userProfileData.email}`}>
                        <div className='mt-3'>Verifieer email</div>
                      </Link>
                    )}
                    <Link to='/password-reset'>
                      <div className='mt-3'>Verander wachtwoord</div>
                    </Link>
                    <div className='mt-3'>Account sluiten</div>
                  </div>
                </div>
              )}
            </div>
            <div className='col-12 col-md-8'>
              <div className='card mb-3'>
                <div className='card-body'>
                  <div className='row'>
                    <EditableInput
                      name='Naam'
                      value={userProfileData.name}
                      editMode={false}
                      handleChange={handleChange}
                      errors={errors['name']}
                    />
                  </div>
                  <hr />
                  <div className='row'>
                    <EditableInput
                      name='E-mailadres'
                      value={userProfileData.email}
                      editMode={false}
                      handleChange={handleChange}
                      errors={errors['email']}
                    />
                  </div>
                </div>
              </div>
              <AlgorithmTable algorithms={algorithms} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
